<template>
    <div class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <button class="modal-close" @click="close">&times;</button>
        <!-- Use a slot so you can inject your custom content -->
        <slot></slot>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'Modal',
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    background: var(--blue-gradient);
    padding: 2rem;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    color: var(--white);
    font-family: var(--font-family, sans-serif);
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
  }
</style>
