<template>
  <div
    :tabindex="tabIndex" class="slide b-black">
    <h2 class="b-blue"> Let's get to work <span class="t-black">.</span></h2>
    <div>
      <p>
        The most important ingredient is to get up and do something. It's as simple as that. Many people have ideas, but very few decide to do something with them, now. Not tomorrow. Not next week. Today. The true entrepreneur is a doer, not a dreamer.
        <br>
        <br>
      </p>
      <h3>Some initiatives I have been involved in<span></span> ⤵️ </h3>
      <div class="image-slide">
        <a
          v-for="(item, i) in imgList"
          :key="i"
          :href="item.link || '#'"
          target="_blank"
          @click="handleItemClick(item, $event)">
          <img
            :src="item.src"
            :alt="item.title"
            height="113"
            width="200">
          <label>{{ item.title }}</label>
        </a>
      </div>
    </div>

      <!-- Render the modal when isModalOpen is true -->
      <Modal v-if="isModalOpen" @close="isModalOpen = false">
      <h3>Some opportunities to Share</h3>
      <div class="card-container">
        <div class="card" v-for="(link, index) in externalLinks" :key="index">
          <a :href="link.url" target="_blank" class="card-link">{{ link.title }}</a>
        </div>
      </div>
    </Modal>

    <div class="disclaimer">
    </div>

    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      :direction="'up'"
      @keydown.enter="updateSlide('prev')"
      @keydown.space="updateSlide('prev')"
      @click="updateSlide('prev')"/>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      @click="updateSlide()"/>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'
import Modal from '@/components/BodyContent/Modal.vue'

export default {
  name: 'MyProjectsSlide',
  mixins: [slideMixin],
  components: { Modal },
  data () {
    return {
      age: new Date(Date.now() - new Date('11/1/17').getTime()).getFullYear() - 1970,
      isModalOpen: false,
      externalLinks: [
        { url: 'https://ekoparty.org/trainings-2023-active-cyber-defense-hunt-the-fox-on-his-land/', title: 'EKOPARTY Trainings - 2023' },
        { url: 'https://contenidos.21.edu.ar/microsites/cordoba-security-conference-2024/programa.html', title: 'Cordoba Cybersecurity Conferense - 2024' },
        { url: 'https://welcu.com/8dot8/88-reloaded-las-vegas', title: '8.8 Reloaded Las Vegas - 2024' },
        { url: 'https://www.blackhat.com/us-24/arsenal/schedule/#dolos-t-deceptive-operations-lure-observe-and-secure-tool-38673', title: 'Black Hat Arsenal - 2024' },
        { url: 'https://ekoparty.org/trainings-2024-active-cyber-defense-hunt-the-fox-on-his/', title: 'EKOPARTY Trainings - 2024' }
      ],
      imgList: [
        {
          src: require('@/assets/img/papers.jpg'),
          link: 'https://www.researchgate.net/profile/Diego-Staino',
          title: 'Papers & Research'
        },
        {
          src: require('@/assets/img/newsletter.jpg'),
          link: 'https://www.linkedin.com/newsletters/writing-cybersecurity-coffee-7024719960866201600/',
          title: 'Writing, CybSec & Coffee'
        },
        {
          src: require('@/assets/img/share.jpg'),
          link: '',
          title: 'Opportunities to share'
        },
        {
          src: require('@/assets/img/dolost.jpg'),
          link: 'https://github.com/Base4Security/DOLOS-T',
          title: 'DolosT'
        }
      ]
    }
  },
  methods: {
    handleItemClick (item, event) {
      if (item.title === 'Opportunities to share') {
        event.preventDefault()
        this.isModalOpen = true
      }
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';

img {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
  margin: 0 1em;
  transition: transform .3s ease;
  border-radius: 10%;
  max-width: 45%;
  max-height: 45%;
}

.slide p {
  margin-bottom: 0;
  font-size: 1.4em;
}

.slide h3 {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;

  span {
    position: absolute;
    font-size: .4em;
    right: 12px;
  }
}

.slide h2 {
  font-size: 3em;
}

.image-slide {
  display: flex;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-decoration: none;

    img:hover + label::before {
      width: 100%;
    }

    label {
      margin-top: .7em;
      font-size: 1.2em;
      position: relative;
      padding: 0 3px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        background-color: var(--yellow);
        height: 100%;
        transition: width 0.3s cubic-bezier(.22,.68,0,1.2);
        z-index: -1;
      }

      &:hover {
        cursor: pointer;

        &::before {
          width: 100%;
        }
      }
    }
  }
}

p {
  margin-bottom: 0;
}

h3 {
  margin-top: 0;
}

.disclaimer {
  font-size: .8em;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}
.card {
  background-color: var(--b-black);  // Using your site's background color
  padding: 1rem;
  border: 1px solid #3f51b5;    // Accent border; adjust as needed
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 150px;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-3px);
  }
}

.card-link {
  background-color: var(--yellow);
  color: var(--b-black);
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #3f51b5;
  }
}
@media screen and (max-width: $tablet-breakpoint) {
  .slide {
    .image-slide {
      max-width: 100%;
      flex-wrap: wrap;

      a {
        width: 100%;

        label {
          background-color: var(--yellow);
          font-weight: bold;
          padding: 5px;
          letter-spacing: 0.05em;

          &::before {
            display: none;
          }
        }
      }

      img {
        display: none;
      }
    }

    h3 {
      span {
        right: 8px;
      }
    }
  }

  .slide .arrow-container.arrow-down {
      bottom: 3.5em;
  }
}
</style>
